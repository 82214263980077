<template>
  <el-dialog title="温馨提示" :visible.sync="visible" @close="closeDialog" class="table" width="460px">
    <div class="pop-center-list">
      <div class="code">
        <img :src="wechatImg" alt />
        <p>海L/SS视频 海L/SS 223开图片 下载</p>
        <p>请联系微信客服下载</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["dVisible"],
  watch: {
    dVisible: {
      handler(val) {
        this.visible = val;
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      wechatImg: require("@/assets/images/code.png")
    };
  },
  methods: {
    linkOverseas(row) {
      window.open(row.enUrl);
    },
    linkDomestic(row) {
      window.open(row.zhUrl);
    },
    closeDialog() {
      this.$emit("closeDialogs");
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

  .pop-center-list {
    text-align: center;
    width: 410px;
    z-index: 22;
    .code {
      padding: 20px 0 10px;
      text-align: center;
      img {
        height: 180px;
        width: 180px;
      }
      p {
        margin-top: 6px;
        font-size: 18px;
        color: #666;
      }
    }
  }

</style>
